import React, { useState } from "react";
import CountWord from "../components/CountWord";
import ResponsiveAppBar from "../components/Header";
import InputText from "../components/InputText";
import Footer from "../components/Footer";

export default function Home() {
  const [value, setValue] = useState("");
  return (
    <>
      <ResponsiveAppBar />
      <InputText value={value} setValue={setValue} />
      <CountWord value={value} />
      <Footer />
    </>
  );
}
