import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function CountWord({ value }) {
  const handleCountWords = (str) => {
    if (value !== "") {
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      return str.split(" ").length;
    } else {
      return 0;
    }
  };
  return (
    <>
      <Box sx={{ bgcolor: "background.paper", mt: 5 }}>
        <Typography variant="body2" align="center">
          Character Count: {value.length} | Word Count:{" "}
          {handleCountWords(value)}
        </Typography>
      </Box>
    </>
  );
}
