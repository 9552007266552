import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CopyIcon from "@mui/icons-material/CopyAll";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalCase, sentenceCase, camelCase, pascalCase } from "change-case";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";

export default function InputText({ value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleUpperCase = () => {
    setValue(value.toUpperCase());
  };

  const handleLowerCase = () => {
    setValue(value.toLowerCase());
  };

  const handleCapitalizedCase = () => {
    setValue(capitalCase(value));
  };

  const handleSentenceCase = () => {
    setValue(sentenceCase(value));
  };

  const handleCamelCase = () => {
    setValue(camelCase(value));
  };

  const handlePascalCase = () => {
    setValue(pascalCase(value));
  };

  const handleDownloadTxtFile = () => {
    if (value !== "") {
      const element = document.createElement("a");
      const file = new Blob([value], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "convertcase.txt";
      document.body.appendChild(element);
      element.click();
      toast.info("Text downloaded!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Please type or paste your content!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const notify = () => {
    toast.info("Text copied to clipboard!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="container">
      <Box noValidate autoComplete="off">
        <TextField
          id="outlined-multiline-static"
          label="Convert Case"
          focused
          multiline
          rows={17}
          fullWidth
          value={value}
          placeholder="type or paste your content here"
          onChange={handleChange}
        />
      </Box>
      <div className="container-btn">
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleSentenceCase}
        >
          Sentence case
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleLowerCase}
        >
          lower case
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleUpperCase}
        >
          UPPER CASE
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleCapitalizedCase}
        >
          Capitalized Case
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleCamelCase}
        >
          camelCase
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handlePascalCase}
        >
          PascalCase
        </Button>
        <CopyToClipboard text={value}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", mr: 1, mt: 1 }}
            onClick={notify}
            startIcon={<CopyIcon />}
          >
            Copy to Clipboard
          </Button>
        </CopyToClipboard>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={handleDownloadTxtFile}
          startIcon={<DownloadIcon />}
        >
          Download Text
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", mr: 1, mt: 1 }}
          onClick={() => setValue("")}
          startIcon={<DeleteIcon />}
        >
          Clear Text
        </Button>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
