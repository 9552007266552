import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function Footer() {
  return (
    <>
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="body2" color="text.secondary" align="center">
          Created with ❤️ by
          <Link color="inherit" href="https://hadyd.dev/">
            &nbsp;Anur Al Hadyd
          </Link>{" "}
        </Typography>
      </Box>
    </>
  );
}
